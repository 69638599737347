<template>
	<b-row>
		<b-col offset-sm="1" sm="10" offset-lg="2" lg="8">
			<b-card>
				<!-- Title -->
				<b-card-title class="text-primary">
					{{ title }}
				</b-card-title>

				<!-- FORM -->
				<b-form>
					<!-- PIN Code -->
					<b-form-group label="PIN Code*" label-for="pincode" :invalid-feedback="getErrorMessage(errors, 'pincode')" :state="getErrorState(errors, 'pincode')">
						<b-form-input :state="getErrorState(errors, 'pincode')" type="text" id="pincode" v-model="form.pincode" placeholder="PIN Code" trim />
					</b-form-group>

					<!-- City -->
					<b-form-group label="Town/City*" label-for="city" :invalid-feedback="getErrorMessage(errors, 'city')" :state="getErrorState(errors, 'city')">
						<b-form-input :state="getErrorState(errors, 'city')" type="text" id="city" v-model="form.city" trim />
					</b-form-group>

					<!-- Area -->
					<b-form-group label="Area*" label-for="area" :invalid-feedback="getErrorMessage(errors, 'area')" :state="getErrorState(errors, 'area')">
						<b-form-input :state="getErrorState(errors, 'area')" type="text" id="area" v-model="form.area" trim />
					</b-form-group>

					<!-- Other Details -->
					<b-form-group label="Flat, House no., Building, (Other details to assist delivery)" label-for="otherDetails" :invalid-feedback="getErrorMessage(errors, 'otherDetails')" :state="getErrorState(errors, 'otherDetails')">
						<b-form-input :state="getErrorState(errors, 'otherDetails')" type="text" id="otherDetails" v-model="form.otherDetails" trim />
					</b-form-group>

					<!-- Landmark -->
					<b-form-group label="Landmark" label-for="landmark" :invalid-feedback="getErrorMessage(errors, 'landmark')" :state="getErrorState(errors, 'landmark')">
						<b-form-input :state="getErrorState(errors, 'landmark')" type="text" id="landmark" v-model="form.landmark" placeholder="E.g. Near Sion Station, Behind Imax Cinema, etc." trim />
					</b-form-group>

					<div class="text-center">
						<b-button @click.prevent="save" class="mr-2 my-2" variant="primary">
							Save
						</b-button>
						<b-button @click.prevent="cancel"> Cancel </b-button>
					</div>
				</b-form>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";

import { addAddress, getAddress, updateAddress } from "../../../../apis/user";

export default {
	created() {
		this.fillFormFromPath();
	},
	data() {
		return {
			form: {
				id: "",
				pincode: "",
				city: "",
				area: "",
				otherDetails: "",
				landmark: "",
				// state: "maharashtra",
				// latitude: null,
				// longitude: null,
			},
			errors: null,
		};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
		}),
		title() {
			if (this.isNew) {
				return "Add Address";
			}
			return "Edit Address";
		},
		isNew() {
			return this.form.id ? false : true;
		},
	},
	methods: {
		fillFormFromPath() {
			const addressId = this.$route.query.addressId;
			if (addressId) {
				getAddress(addressId, this.token)
					.then((data) => {
						this.form = { ...data };
					})
					.catch(() => {
						// redirect to list page, since form may not belong to user
						this.$router.replace({ name: "UserAddresses" });
					});
			}
		},
		save() {
			if (this.isNew) {
				addAddress(this.form, this.token)
					.then(this.onSuccessfulSave)
					.catch(this.onFailure);
			} else {
				updateAddress(this.form.id, this.form, this.token)
					.then(this.onSuccessfulSave)
					.catch(this.onFailure);
			}
		},
		cancel() {
			this.$router.go(-1);
		},
		onSuccessfulSave() {
			this.cancel();
		},
		onFailure({ data }) {
			this.errors = data.errors;
		},
	},
};
</script>
